<template>
  <div class="upload__story">
    <div class="upload__story__button" @click="selectImage">
        Subir story
    </div>
    <input type="file" ref="file" @change="onFileChange" name="photo" v-show="false"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {

    data() {
        return {
            image: null,        
            file: null
        }
    },

    methods: {
        async onFileChange(event) {
            this.image = event.target.files[0]
            try {
                await this.createStory(this.image)
                this.$toast.success("Se creo la story exitosamente");
            } catch (error) {
                this.$toast.error(
                  "Ocurrio un error al intentar crear la story"
                );
            }
            
        },

        selectImage() {
            this.$refs.file.click()
        },
        
        ...mapActions('webStore', [ 'createStory' ])
    },
}
</script>